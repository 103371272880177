/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import { Router, useNavigate, useParams, useRoutes } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';
import classNames from 'classnames';
import styles from './SettingsWorkflow.module.scss';
import { doNavigate, getArtifactActionAutocompleteObjects, getArtifactActionDisplayValue, getArtifactTypeAutocompleteObjects, getArtifactTypeDisplayValue, getPDAutocompleteObjects, getPDDisplayValue, handleHttpError, i18n, setDataModified, updateArtifactsCount, uuid } from '../../utils';
import { FieldEditor } from '../../components/FieldEditor';
import { createWorkflowSettings, getProcessDefinitions, getWorkflowSettings, getWorkflowTask, updateWorkflowSettings } from '../../services/pages/workflow';
import { FieldAutocompleteEditor } from '../../components/FieldAutocompleteEditor';
import { getArtifactActions, getArtifactType, getArtifactTypes, getWorkflowableArtifactTypes } from '../../services/pages/artifacts';
import { Button } from '../../components/Button';
import { ArtifactInfo } from '../../components/ArtifactInfo';
import { StaticNoticesArea } from '../../components/StaticNoticesArea';
import { Tabs } from '../../components/Tabs';
import { FieldTextEditor } from '../../components/FieldTextEditor';

export function SettingsWorkflowEdit() {
  const navigate = useNavigate();

  const [, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  const [state, setState] = useUrlState({ sc: 1 }, { navigateMode: 'replace' });

  const [data, setData] = useState({
    artifact_type: '', artifact_action: '', process_definition_key: '', description: ''
  });

  const [showValidation, setShowValidation] = useState(false);
  //const [isReadOnly, setReadOnly] = useState(false);

  const { id } = useParams();
  const [settingsId, setSettingsId] = useState<string>(id ?? '');

  useEffect(() => {
    if (id) { setSettingsId(id); }
    setDataModified(true);
  }, [id]);

  const loadData = () => {
    if (settingsId) {

      const handleData = (json: any) => {
        setData(json);
        setDataModified(false);
        if (document.getElementById(`crumb_${settingsId}`) !== null) {
          document.getElementById(`crumb_${settingsId}`)!.innerText = json.artifact_type;
        }
        setLoading(false); setLoaded(true);
      };

      
        getWorkflowSettings(settingsId)
          .then(handleData)
          .catch(handleHttpError);
      

    }
  }

  useEffect(() => {
    loadData();
  }, [settingsId]);

  const updateSettingsField = (field: string, value: string | string[] | undefined) => {
    setData((prev: any) => ({ ...prev, entity: { ...prev.entity, [field]: value } }));
  };

  const saveData = () => {
    updateWorkflowSettings(settingsId, data).then(json => {
      if (json.metadata && json.metadata.id != settingsId)
        navigate(`/settings/workflows/edit/${json.metadata.id}`);
    }).catch(handleHttpError);
  }

  return (
    <div className={classNames(styles.page, styles.transparent, { [styles.loaded]: isLoaded })}>
      <div className={styles.title_row}>
        <h1 className={styles.title}>{data.artifact_type} {data.artifact_action}</h1>
        <div className={styles.buttons}>
          <Button background='blue' onClick={() => saveData()}>Сохранить</Button>
          <Button background='outlined-blue' onClick={() => loadData()}>Отменить</Button>
        </div>
      </div>
      <div className={styles.artifact_info_row}>
        <ArtifactInfo artifactType={'workflow_settings'} />
      </div>
      <StaticNoticesArea />
      <Tabs onTabChange={(t: number) => { setState(() => ({ sc: t })); }} tabNumber={state.sc} tabs={[
        {
          key: 'tab-gen',
          title: i18n('Сведения'),
          content: <div className={styles.tab_white}>
            <FieldAutocompleteEditor
              label={i18n('Тип объекта')}
              defaultValue={data.artifact_type}
              valueSubmitted={(identity) => updateSettingsField('artifact_type', identity)}
              getDisplayValue={getArtifactTypeDisplayValue}
              getObjects={getArtifactTypeAutocompleteObjects}
              isRequired
              showValidation={showValidation}
            />

            <FieldAutocompleteEditor
              label={i18n('Событие')}
              defaultValue={data.artifact_action}
              valueSubmitted={(identity) => updateSettingsField('artifact_action', identity)}
              getDisplayValue={getArtifactActionDisplayValue}
              getObjects={getArtifactActionAutocompleteObjects}
              isRequired
              showValidation={showValidation}
            />

            <FieldAutocompleteEditor
              label={i18n('Process Definition')}
              defaultValue={data.process_definition_key}
              valueSubmitted={(identity) => updateSettingsField('process_definition_key', identity)}
              getDisplayValue={getPDDisplayValue}
              getObjects={getPDAutocompleteObjects}
              showValidation={showValidation}
            />

            <FieldTextEditor
              label={i18n('Описание события')}
              defaultValue={data.description}
              valueSubmitted={(val) => {
                  updateSettingsField('description', val);
              }}
              isRequired
            />
          </div>
        }
      ]} />

    </div>
  );
}
