import React, { FC, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import styles from './Header.module.scss';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as StewardSvg } from '../../assets/icons/steward.svg';
import { Search } from '../Search';
import { Button } from '../Button';
import { setCookie, deleteCookie, getCookie, i18n, handleHttpError, doNavigate, uuid } from '../../utils';
import { changeTokenAction, changeValidateAction } from '../../redux/reducers/auth';
import { Crumbs } from '../Crumbs';
import { LimitStewardSwitch } from '../LimitStewardSwitch/LimitStewardSwitch';
import { DashboardSwitch } from '../DashboardSwitch/DashboardSwitch';

export const Header: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = (disp: Dispatch) => {
    deleteCookie('token');
    deleteCookie('login');
    disp(changeTokenAction(null));
    disp(changeValidateAction(null));
    navigate('/signin');
  };

  

  const [searchParams] = useSearchParams();
  

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          <a href="" className={styles.logo_link} onClick={(e) => { e.preventDefault(); doNavigate('/', navigate); }}>
            <Logo /><h1 className={styles.sitetitle}>Lottabyte</h1>
          </a>
        </div>
        <div className={styles.search}>
          <Search query={searchParams.get('q')} />
          <DashboardSwitch key={uuid()} cookieKey={'top'} ref={(dashboardSwitch:any) => { (window as any).dashboardSwitch = dashboardSwitch; }} />
        </div>
        <div className={styles.actions}>
        <Button
            className={styles.btn_icon}
            background="none"
            onClick={() => { navigate('/account'); }}
          >
            <ProfileIcon />
          </Button>
          <Button
            background="outlined-blue"
            onClick={() => logoutHandler(dispatch)}
          >
            {i18n('Exit')}
          </Button>
        </div>
      </header>
      
    </>
  );
};
