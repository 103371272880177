/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './SettingsUsers.module.scss';
import { handleHttpError, i18n, uuid } from '../../utils';
import {
  getUser, createUser, updateUser, getRoles,
} from '../../services/pages/users';
import { FieldCheckboxListEditor } from '../../components/FieldCheckboxListEditor';
import { getDomains } from '../../services/pages/domains';
import classNames from 'classnames';
import { Button } from '../../components/Button';
import { ArtifactInfo } from '../../components/ArtifactInfo';
import useUrlState from '@ahooksjs/use-url-state';
import { StaticNoticesArea } from '../../components/StaticNoticesArea';
import { Tabs } from '../../components/Tabs';
import { FieldTextEditor } from '../../components/FieldTextEditor';

export function SettingsUser() {
  const [, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  const [state, setState] = useUrlState({ sc: 1 }, { navigateMode: 'replace' });
  const [data, setData] = useState({
    username: '',
    display_name: '',
    password: '',
    user_roles: [],
    user_domains: [],
    email: ''
  });

  const navigate = useNavigate();

  const [showValidation, setShowValidation] = useState(true);
  const [userId, setUserId] = useState<string>('');

  const { id } = useParams();

  useEffect(() => {
    if (!userId && id) setUserId(id);
  }, [id]);

  const loadData = () => {
    if (userId) {
      getUser(userId)
        .then((json: any) => {
          setData(json);
          const el = document.getElementById(`crumb_${userId}`);
          if (el) el.innerText = json.username;
          setLoading(false);
          setLoaded(true);
        })
        .catch(handleHttpError);
    }
  }

  useEffect(() => {
    loadData();
  }, [userId]);

  /*useEffect(() => {
    if (stewardId) {
      getSteward(stewardId).then((json: any) => {
        setStewardData(json);
      }).catch(handleHttpError);
    }
  }, [stewardId]);*/

  const updateUserField = (field: string, value: any) => {
    if (field == 'password' && !value)
      value = undefined;
    setData((prev: any) => ({ ...prev, [field]: value } ));
    
  };

  const saveData = () => {
    
    updateUser(userId, data).then(json => {
      if (json && json.id && json.id !== userId) {
        navigate(`/settings/users/edit/${encodeURIComponent(json.id)}`);
      }
    }).catch(handleHttpError);
  }

  return (
    <div className={classNames(styles.page, styles.transparent, { [styles.loaded]: isLoaded })}>
      <div className={styles.title_row}>
        <h1 className={styles.title}>{data.username}</h1>
        <div className={styles.buttons}>
          <Button background='blue' onClick={() => saveData()}>Сохранить</Button>
          <Button background='outlined-blue' onClick={() => loadData()}>Отменить</Button>
        </div>
      </div>
      <div className={styles.artifact_info_row}>
        <ArtifactInfo artifactType={'user'} />
      </div>
      <StaticNoticesArea />
      <Tabs onTabChange={(t: number) => { setState(() => ({ sc: t })); }} tabNumber={state.sc} tabs={[
        {
          key: 'tab-gen',
          title: i18n('Сведения'),
          content: <div className={styles.tab_white}>
            <FieldTextEditor
              label={i18n('Логин')}
              defaultValue={data.username}
              valueSubmitted={(value) => updateUserField('username', value)}
              isRequired
              showValidation={showValidation}
            />

            <FieldTextEditor
              label={i18n('E-mail')}
              defaultValue={data.email}
              valueSubmitted={(value) => updateUserField('email', value)}
              isRequired
              showValidation={showValidation}
            />

            <FieldTextEditor
              label={i18n('Имя')}
              isReadOnly={false}
              defaultValue={data.display_name}
              valueSubmitted={(value) => updateUserField('display_name', value)}
              isRequired
              showValidation={showValidation}
            />

            <FieldTextEditor
              label={i18n('Пароль')}
              defaultValue={undefined /*isCreateMode ? '' : '******'*/}
              isPassword
              showValidation={showValidation}
              valueSubmitted={(value) => updateUserField('password', value)}
            />

            <FieldCheckboxListEditor
              label={i18n('Роли')}
              defaultValue={data.user_roles}
              valueSubmitted={(value) => updateUserField('user_roles', value)}
              isRequired={false}
              showValidation={showValidation}
              getObjects={() => getRoles().then((json) => {
                return json;
              })}
            />

            <FieldCheckboxListEditor
              label={i18n('Домены')}
              isReadOnly={false}
              defaultValue={data.user_domains}
              valueSubmitted={(value) => updateUserField('user_domains', value)}
              showValidation={showValidation}
              getObjects={() => getDomains({ filters: [], filters_for_join: [], global_query: '', limit: 1000, offset: 0, sort: 'name+', state: 'PUBLISHED' }).then((json) => {
                return json.items;
              })}
            />
          </div>
        }
      ]} />
    </div>
  );
}
