import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './ArtifactInfo.module.scss';
import { ReactComponent as Domains } from '../../assets/icons/domains-icon.svg';
import { ReactComponent as Systems } from '../../assets/icons/systems-icon.svg';
import { ReactComponent as LogicObjects } from '../../assets/icons/lo-icon.svg';
import { ReactComponent as Queries } from '../../assets/icons/requests-icon.svg';
import { ReactComponent as Samples } from '../../assets/icons/samples-icon.svg';
import { ReactComponent as Assets } from '../../assets/icons/assets-icon.svg';
import { ReactComponent as Indicators } from '../../assets/icons/indicators-icon.svg';
import { ReactComponent as BusinessEntities } from '../../assets/icons/business-ent-icon.svg';
import { ReactComponent as Products } from '../../assets/icons/products-icon.svg';
import { ReactComponent as Tasks } from '../../assets/icons/tasks-icon.svg';
import { ReactComponent as DQRules } from '../../assets/icons/dq-rule.svg';
import { i18n } from '../../utils';


type ArtifactInfoProps = {
    artifactType: string;
    tagNames?: string[];
    state?: string;
    type?: string;
};

export const ArtifactInfo: FC<ArtifactInfoProps> = ({ artifactType, tagNames, state, type }) => {

    return (
        <div className={styles.artifact_info}>
            <div className={classNames(styles.artifact_type, { [styles.transparent]: type == 'transparent' })}>
                {artifactType == 'domain' && (<><Domains /><div className={styles.name}>{i18n('Домен')}</div></>)}
                {artifactType == 'system' && (<><Systems /><div className={styles.name}>{i18n('Система')}</div></>)}
                {artifactType == 'entity' && (<><LogicObjects /><div className={styles.name}>{i18n('Логический объект')}</div></>)}
                {artifactType == 'entity_attribute' && (<><LogicObjects /><div className={styles.name}>{i18n('Атрибут ЛО')}</div></>)}
                {artifactType == 'entity_query' && (<><Queries /><div className={styles.name}>{i18n('Запрос')}</div></>)}
                {artifactType == 'entity_sample' && (<><Samples /><div className={styles.name}>{i18n('Сэмпл')}</div></>)}
                {artifactType == 'data_asset' && (<><Assets /><div className={styles.name}>{i18n('Актив')}</div></>)}
                {artifactType == 'indicator' && (<><Indicators /><div className={styles.name}>{i18n('Показатель')}</div></>)}
                {artifactType == 'business_entity' && (<><BusinessEntities /><div className={styles.name}>{i18n('Бизнес-сущность')}</div></>)}
                {artifactType == 'product' && (<><Products /><div className={styles.name}>{i18n('Продукт')}</div></>)}
                {artifactType == 'task' && (<><Tasks /><div className={styles.name}>{i18n('Задача')}</div></>)}
                {artifactType == 'dq_rule' && (<><DQRules /><div className={styles.name}>{i18n('Правило проверки качества')}</div></>)}
                {artifactType == 'meta_database' && (<><LogicObjects /><div className={styles.name}>{i18n('Метаданные')}</div></>)}
                {artifactType == 'meta_object' && (<><LogicObjects /><div className={styles.name}>{i18n('Метаобъект')}</div></>)}
                {artifactType == 'meta_column' && (<><LogicObjects /><div className={styles.name}>{i18n('Колонка')}</div></>)}
                {artifactType == 'user' && (<div className={styles.name}>{i18n('Пользователь')}</div>)}
                {artifactType == 'system_connection' && (<div className={styles.name}>{i18n('Подключение')}</div>)}
                {artifactType == 'role' && (<div className={styles.name}>{i18n('Роль')}</div>)}
                {artifactType == 'group' && (<div className={styles.name}>{i18n('Группа')}</div>)}
                {artifactType == 'steward' && (<div className={styles.name}>{i18n('Стюард')}</div>)}
                {artifactType == 'workflow_settings' && (<div className={styles.name}>{i18n('Процесс')}</div>)}
            </div>
            {state && (<div className={styles.artifact_state}>{state == 'PUBLISHED' ? 'Опубликован' : ( state == 'ARCHIVED' ? 'Архив' : 'Черновик')}</div>)}
        </div>
    );
};
