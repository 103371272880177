import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import styles from './SettingsSteward.module.scss';
import { getDomainAutocompleteObjects, getTablePageSize, getUserAutocompleteObjects, getUserDisplayValue, handleHttpError, i18n, uuid } from '../../utils';
import { Table } from '../../components/Table';
import { Loader } from '../../components/Loader';
import { createSteward, deleteSteward } from '../../services/pages/stewards';
import classNames from 'classnames';
import { Button } from '../../components/Button';
import { DeleteObjectModal } from '../../components/DeleteObjectModal';
import { ModalDlg } from '../../components/ModalDlg';
import { FieldTextEditor } from '../../components/FieldTextEditor';
import { FieldAutocompleteEditor } from '../../components/FieldAutocompleteEditor';
import { FieldMultipleCheckboxEditor } from '../../components/FieldMultipleCheckboxEditor';

export const SettingsStewards = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const [, setLoading] = useState(false);

  const [showDelDlg, setShowDelDlg] = useState(false);
  const [delObjectData, setDelObjectData] = useState<any>({ id: '', username: '' });
  const [showCreateDlg, setShowCreateDlg] = useState(false);
  const [showCreateValidation, setShowCreateValidation] = useState(false);
  const [createData, setCreateData] = useState<any>({ name: '', user_id: '', domains: [] });

  const delDlgSubmit = () => {
    setShowDelDlg(false);
    setLoading(true);
    deleteSteward(delObjectData.id)
      .then(() => {
        setLoading(false);
      })
      .catch(handleHttpError);
    setDelObjectData({ id: '', name: '' });
  };

  const columns = [
    { property: 'id', header: 'ID', isHidden: true },
    {
      property: 'name',
      header: i18n('Имя'),
    },
    {
      property: 'description',
      header: i18n('Описание'),
    },
    {
      property: 'domains',
      header: i18n('Домены'),
      sortDisabled: true,
      render: (item: any) => (
        <span className={styles.value}>
          {item.domains.map((t: any) => (
            <div key={uuid()} style={{ marginBottom: '5px' }}>{t.name}</div>
          ))}
        </span>
      ),
    },
  ];

  const submitCreate = () => {
    if (createData.name && createData.user_id && createData.domains && createData.domains.length > 0) {
      setShowCreateDlg(false);

      createSteward(createData).then(json => {
        if (json && json.metadata.id) {
          navigate(`/settings/stewards/edit/${encodeURIComponent(json.metadata.id)}`);
        }
      }).catch(handleHttpError)
    } else
      setShowCreateValidation(true);
  }

  return (
    <div className={classNames(styles.page, styles.scrollable, { [styles.loaded]: loaded })}>
      {!loaded ? (
        <Loader className="centrify" />
      ) : (
        <>
          <div className={styles.title}>{`${i18n('Стюарды')}`}<Button background='blue' onClick={() => { setShowCreateValidation(false); setShowCreateDlg(true); }}>Создать стюарда</Button></div>
          <Table
            cookieKey='stewards'
            className={styles.table}
            columns={columns}
            paginate
            columnSearch
            globalSearch
            dataUrl="/v1/stewards/search"
            initialFetchRequest={{
              sort: 'name+',
              global_query: '',
              limit: getTablePageSize(),
              offset: 0,
              filters: [],
              filters_preset: [],
              filters_for_join: [],
            }}
            onRowClick={(row: any) => {
              navigate(`/settings/stewards/edit/${encodeURIComponent(row.id)}`);
            }}
            renderActionsPopup={(row: any) => (
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate('/settings/stewards/edit/');
                    return false;
                  }}
                  className={styles.btn_create}
                />
                <a
                  href={`/settings/stewards/edit/${encodeURIComponent(row.id)}`}
                  className={styles.btn_edit}
                  onClick={(e) => { e.preventDefault(); navigate(`/settings/stewards/edit/${encodeURIComponent(row.id)}`); }}
                />
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setDelObjectData({ id: row.id, name: row.name });
                    setShowDelDlg(true);
                    return false;
                  }}
                  className={styles.btn_del}
                />
              </div>
            )}
          />
          
          <DeleteObjectModal show={showDelDlg} objectTitle={delObjectData.username} onClose={() => { setShowDelDlg(false); return false; }} onSubmit={delDlgSubmit} />
          <ModalDlg show={showCreateDlg} title={i18n('Создать стюарда')} cancelBtnText={i18n('Отменить')} submitBtnText={i18n('Создать')} onClose={() => setShowCreateDlg(false)} dialogClassName={styles.dlg_create} onSubmit={submitCreate}>
            <div className={styles.fields}>
                <FieldTextEditor label={i18n('ФИО')} isRequired showValidation={showCreateValidation} defaultValue='' valueSubmitted={(v) => setCreateData((prev:any) => ({...prev, name: v ?? ''}))} />

                <FieldAutocompleteEditor
                  label={i18n('Пользователь')}
                  defaultValue={''}
                  valueSubmitted={(v) => setCreateData((prev:any) => ({...prev, user_id: v ?? ''}))}
                  getDisplayValue={getUserDisplayValue}
                  getObjects={getUserAutocompleteObjects}
                  isRequired
                  showValidation={showCreateValidation}
                />

                <FieldMultipleCheckboxEditor
                  isScroll
                  label={i18n('Домены')}
                  defaultValues={[]}
                  valueSubmitted={(ids) => setCreateData((prev:any) => ({...prev, domains: ids}))}
                  dataSet={getDomainAutocompleteObjects}
                  isRequired
                  showValidation={showCreateValidation}
                />
            </div>
          </ModalDlg>
        </>
      )}
    </div>
  );
};
