/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import styles from './SettingsGroups.module.scss';
import { getTablePageSize, handleHttpError, i18n, uuid } from '../../utils';
import { Table } from '../../components/Table';
import { Loader } from '../../components/Loader';
import { createGroup, deleteGroup } from '../../services/pages/groups';
import { getPermissions, getRoles } from '../../services/pages/users';
import classNames from 'classnames';
import { Button } from '../../components/Button';
import { DeleteObjectModal } from '../../components/DeleteObjectModal';
import { ModalDlg } from '../../components/ModalDlg';
import { FieldTextEditor } from '../../components/FieldTextEditor';

export function SettingsGroups() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const [, setLoading] = useState(false);
  
  const [showDelDlg, setShowDelDlg] = useState(false);
  const [delObjectData, setDelObjectData] = useState<any>({ id: '', username: '' });
  const [showCreateDlg, setShowCreateDlg] = useState(false);
  const [showCreateValidation, setShowCreateValidation] = useState(false);
  const [createData, setCreateData] = useState({ name: '', system_id: '' });

  const delDlgSubmit = () => {
    setShowDelDlg(false);
    setLoading(true);
    deleteGroup(delObjectData.id)
      .then(() => {
        setLoading(false);
      })
      .catch(handleHttpError);
    setDelObjectData({ id: '', name: '' });
  };

  const columns = [
    { property: 'id', header: 'ID', isHidden: true },
    {
      property: 'name',
      header: i18n('Название группы'),
    },
    {
      property: 'user_role_names',
      filter_property: 'user_role_names',
      header: i18n('Роли'),
      
    },
    {
      property: 'permission_names',
      filter_property: 'permission_names',
      header: i18n('Разрешения'),
      render: (item: any) => (
        <span className={styles.value}>
          {item.permissions.join(', ')}
        </span>
      ),
    }
  ];

  const submitCreate = () => {
    if (createData.name) {
      setShowCreateDlg(false);

      createGroup(createData).then(json => {
        if (json && json.metadata.id) {
          navigate(`/settings/groups/edit/${encodeURIComponent(json.metadata.id)}`);
        }
      }).catch(handleHttpError)
    } else
      setShowCreateValidation(true);
  }

  return (
    <div className={classNames(styles.page, styles.scrollable, { [styles.loaded]: loaded })}>
      {!loaded ? (
        <Loader className="centrify" />
      ) : (
        <>
          <div className={styles.title}>{`${i18n('Группы')}`}<Button background='blue' onClick={() => { setShowCreateValidation(false); setShowCreateDlg(true); }}>Создать группу</Button></div>
          <Table
            cookieKey='groups'
            className={styles.table}
            columns={columns}
            paginate
            columnSearch
            globalSearch
            dataUrl="/v1/usermgmt/external_group/search"
            initialFetchRequest={{
              sort: 'name+',
              global_query: '',
              limit: getTablePageSize(),
              offset: 0,
              filters: [],
              filters_preset: [],
              filters_for_join: [],
            }}
            onRowClick={(row: any) => {
              navigate(`/settings/groups/edit/${encodeURIComponent(row.id)}`);
            }}
            processRows={async (rows:any[]) => {
              return getRoles().then(roles => {

                return getPermissions().then(perms => {
                  return rows.map((r:any) => ({...r, user_roles: r.user_roles.map((rid:string) => { let d = roles.find((rf:any) => rf.id == rid); return d ? d.name : '-'; })
                  , permissions: r.permissions.map((rid:string) => { let d = perms.find((rf:any) => rf.id == rid); return d ? d.name : '-'; })}));
                });
              });
            }}
            renderActionsPopup={(row: any) => (
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate('/settings/groups/edit/');
                    return false;
                  }}
                  className={styles.btn_create}
                />
                <a
                  href={`/settings/groups/edit/${encodeURIComponent(row.id)}`}
                  className={styles.btn_edit}
                  onClick={(e) => { e.preventDefault(); navigate(`/settings/groups/edit/${encodeURIComponent(row.id)}`); }}
                />
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setDelObjectData({ id: row.id, name: row.name });
                    setShowDelDlg(true);
                    return false;
                  }}
                  className={styles.btn_del}
                />
              </div>
            )}
          />
          

          <DeleteObjectModal show={showDelDlg} objectTitle={delObjectData.username} onClose={() => { setShowDelDlg(false); return false; }} onSubmit={delDlgSubmit} />
          <ModalDlg show={showCreateDlg} title={i18n('Создать группу')} cancelBtnText={i18n('Отменить')} submitBtnText={i18n('Создать')} onClose={() => setShowCreateDlg(false)} dialogClassName={styles.dlg_create} onSubmit={submitCreate}>
            <div className={styles.fields}>
                <FieldTextEditor label={i18n('Название')} isRequired showValidation={showCreateValidation} defaultValue='' valueSubmitted={(v) => setCreateData((prev) => ({...prev, name: v ?? ''}))} />
            </div>
          </ModalDlg>
        </>
      )}
    </div>
  );
}
