import React, { useState } from 'react';
import styles from './SettingsRoles.module.scss';
import { getTablePageSize, handleHttpError, i18n } from '../../utils';
import { Table } from '../../components/Table';
import { Loader } from '../../components/Loader';
import { createRole, deleteRole } from '../../services/pages/roles';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';
import { Button } from '../../components/Button';
import { DeleteObjectModal } from '../../components/DeleteObjectModal';
import { ModalDlg } from '../../components/ModalDlg';
import { FieldTextEditor } from '../../components/FieldTextEditor';

export const SettingsRoles = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const [, setLoading] = useState(false);
  
  const [showDelDlg, setShowDelDlg] = useState(false);
  const [delObjectData, setDelObjectData] = useState<any>({ id: '', username: '' });
  const [showCreateDlg, setShowCreateDlg] = useState(false);
  const [showCreateValidation, setShowCreateValidation] = useState(false);
  const [createData, setCreateData] = useState({ name: '' });

  const delDlgSubmit = () => {
    setLoading(true);
    setShowDelDlg(false);
    deleteRole(delObjectData.id)
      .then(() => {
        setLoading(false);
      })
      .catch(handleHttpError);
    setDelObjectData({ id: '', name: '' });
  };

  const columns = [
    { property: 'id', header: 'ID', isHidden: true },
    {
      property: 'name',
      header: i18n('Название'),
    },
    {
      property: 'description', header: i18n('Описание'),
    },
  ];

  const submitCreate = () => {
    if (createData.name) {
      setShowCreateDlg(false);

      createRole(createData).then(json => {
        if (json && json.id) {
          navigate(`/settings/roles/edit/${encodeURIComponent(json.id)}`);
        }
      }).catch(handleHttpError)
    } else
      setShowCreateValidation(true);
  }

  return (
    <div className={classNames(styles.page, styles.scrollable, { [styles.loaded]: loaded })}>
      {!loaded ? (
        <Loader className="centrify" />
      ) : (
        <>
          <div className={styles.title}>{`${i18n('Роли')}`}<Button background='blue' onClick={() => { setShowCreateValidation(false); setShowCreateDlg(true); }}>Создать роль</Button></div>
          <Table
            cookieKey='roles'
            className={styles.table}
            columns={columns}
            paginate
            columnSearch
            globalSearch
            dataUrl="/v1/usermgmt/roles/search"
            initialFetchRequest={{
              sort: 'name+',
              global_query: '',
              limit: getTablePageSize(),
              offset: 0,
              filters: [],
              filters_preset: [],
              filters_for_join: [],
            }}
            onRowClick={(row: any) => {
              navigate(`/settings/roles/edit/${encodeURIComponent(row.id)}`);
            }}
            renderActionsPopup={(row: any) => (
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate('/settings/roles/edit/');
                    return false;
                  }}
                  className={styles.btn_create}
                />
                <a
                  href={`/settings/roles/edit/${encodeURIComponent(row.id)}`}
                  className={styles.btn_edit}
                  onClick={(e) => { e.preventDefault(); navigate(`/settings/roles/edit/${encodeURIComponent(row.id)}`); }}
                />
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setDelObjectData({ id: row.id, name: row.name });
                    setShowDelDlg(true);
                    return false;
                  }}
                  className={styles.btn_del}
                />
              </div>
            )}
          />
          
          <DeleteObjectModal show={showDelDlg} objectTitle={delObjectData.username} onClose={() => { setShowDelDlg(false); return false; }} onSubmit={delDlgSubmit} />
          <ModalDlg show={showCreateDlg} title={i18n('Создать роль')} cancelBtnText={i18n('Отменить')} submitBtnText={i18n('Создать')} onClose={() => setShowCreateDlg(false)} dialogClassName={styles.dlg_create} onSubmit={submitCreate}>
            <div className={styles.fields}>
                <FieldTextEditor label={i18n('Название')} isRequired showValidation={showCreateValidation} defaultValue='' valueSubmitted={(v) => setCreateData((prev) => ({...prev, name: v ?? ''}))} />
            </div>
          </ModalDlg>
        </>
      )}
    </div>
  );
};
