/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import styles from './BusinessEntity.module.scss';
import { getBusinessEntityDisplayValue, getDataTypeAutocompleteObjects, getDataTypeDisplayValue, getDomainAutocompleteObjects, getDomainDisplayValue, handleHttpError, i18n, loadEditPageData, rateClickedHandler, setCookie, setDataModified, tagAddedHandler, tagDeletedHandler, updateArtifactsCount, updateEditPageReadOnly, uuid } from '../../utils';
import { Versions, VersionData } from '../../components/Versions';

import { FieldEditor } from '../../components/FieldEditor';
import { Input } from '../../components/Input';
import { Textarea } from '../../components/Textarea';
import {
  deleteBusinessEntity,
  getBusinessEntityVersion,
  getBusinessEntity,
  getBusinessEntityVersions,
  updateBusinessEntity,
  getBusinessEntities,
  restoreBusinessEntityVersion,
  archiveBusinessEntity,
  restoreBusinessEntity,
} from '../../services/pages/businessEntities';

import { setRecentView } from '../../services/pages/recentviews';
import { WFItemControl } from '../../components/WFItemControl/WFItemControl';
import { FieldArrayEditor } from '../../components/FieldArrayEditor/FieldArrayEditor';
import { FieldTextareaEditor } from '../../components/FieldTextareaEditor';
import { TagProp, Tags } from '../../components/Tags';
import { ReactComponent as PlusInCircle } from '../../assets/icons/plus-blue.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { FieldAutocompleteEditor } from '../../components/FieldAutocompleteEditor';
import { Table } from '../../components/Table';
import { assetsTableColumns, entityTableColumns } from '../../mocks/logic_objects';
import { Tabs } from '../../components/Tabs';
import { userInfoRequest } from '../../services/auth';
import { RelatedObjectsControl } from '../../components/RelatedObjectsControl';
import { Responsibles } from '../../components/Responsibles';
import { DeleteObjectModal } from '../../components/DeleteObjectModal';
import { FieldVisualEditor } from '../../components/FieldVisualEditor';
import { EditPage } from '../../components/EditPage';
import { FieldTextEditor } from '../../components/FieldTextEditor';

export function BusinessEntity() {
  const navigate = useNavigate();

  const [, setLoading] = useState(true);

  const [data, setData] = useState({
    metadata: { id: '', artifact_type: 'business_entity', version_id: '', tags: [], state: 'PUBLISHED', published_id: '', ancestor_draft_id: '', workflow_task_id: '', created_by: '' },
    entity: {
      name: '', description: '', tech_name: '', definition: '', regulation: '', alt_names: [], synonym_ids: [], be_link_ids: [], domain_id: null, parent_id: null,
      formula: '', examples: '', link: '', datatype_id: null, limits: '', roles: '', short_description: ''
    },
  });
  const [tags, setTags] = useState<TagProp[]>([]);
  const [showValidation, setShowValidation] = useState(false);

  const [isReadOnly, setReadOnly] = useState(true);
  const [isLoaded, setLoaded] = useState(false);

  const { id, version_id } = useParams();

  const [businessEntityId, setBusinessEntityId] = useState<string>(id ?? '');
  const [businessEntityVersionId, setBusinessEntityVersionId] = useState<string>(version_id ?? '');

  useEffect(() => {
    if (id) setBusinessEntityId(id);
    setBusinessEntityVersionId(version_id ?? '');
    setDataModified(false);
  }, [id, version_id]);

  const updateBEField = (field: string, value: string | string[] | undefined) => {
    setData((prev: any) => ({ ...prev, entity: { ...prev.entity, [field]: value } }));
    setDataModified(true);
  };

  const addSynonym = () => {
    setData((prev: any) => ({ ...prev, entity: { ...prev.entity, synonym_ids: [...prev.entity.synonym_ids, ''] } }));
  };

  const delSynonym = (k: number) => {
    const arr: string[] = [...data.entity.synonym_ids];
    arr.splice(k, 1);

    updateBEField('synonym_ids', arr.filter((x) => x));
  };

  const addBELink = () => {
    setData((prev: any) => ({ ...prev, entity: { ...prev.entity, be_link_ids: [...prev.entity.be_link_ids, ''] } }));
  };

  const delBELink = (k: number) => {
    const arr: string[] = [...data.entity.be_link_ids];
    arr.splice(k, 1);

    updateBEField('be_link_ids', arr.filter((x) => x));
  };

  const updateBESynonymId = (k: number, id: string) => {
    const arr: string[] = [...data.entity.synonym_ids];
    if (arr.length > k) { arr[k] = id; } else { arr.push(id); }

    updateBEField('synonym_ids', arr.filter((x) => x));
  };

  const updateBELink = (k: number, id: string) => {
    const arr: string[] = [...data.entity.be_link_ids];
    if (arr.length > k) { arr[k] = id; } else { arr.push(id); }

    updateBEField('be_link_ids', arr.filter((x) => x));
  };

  const getBEObjects = async (search: string) => getBusinessEntities({
    sort: 'name+',
    global_query: search,
    limit: 1000,
    offset: 0,
    filters: [...data.entity.synonym_ids, data.metadata.id, data.metadata.published_id ?? ''].filter((id) => id).map((id) => ({ column: 'id', value: id, operator: 'NOT_EQUAL' })),
    filters_for_join: [],
    state: 'PUBLISHED',
  }).then((json) => json.items);

  const getBELinkObjects = async (search: string) => getBusinessEntities({
    sort: 'name+',
    global_query: search,
    limit: 1000,
    offset: 0,
    filters: [...data.entity.be_link_ids, data.metadata.id, data.metadata.published_id ?? ''].filter((id) => id).map((id) => ({ column: 'id', value: id, operator: 'NOT_EQUAL' })),
    filters_for_join: [],
    state: 'PUBLISHED',
  }).then((json) => json.items);

  const getParentBEAutocompleteObjects = async (search: string) => getBusinessEntities({
    sort: 'name+',
    global_query: search,
    limit: 1000,
    offset: 0,
    filters: [{ column: 'id', value: businessEntityId, operator: 'NOT_EQUAL' }, { column: 'id', value: data.metadata.published_id, operator: 'NOT_EQUAL' }],
    filters_for_join: [],
  }).then((json) => json.items);

  

  return (
    <>
      <EditPage objectId={businessEntityId} objectVersionId={businessEntityVersionId} data={data} restoreVersion={restoreBusinessEntityVersion} urlSlug='business-entities' setData={setData} isReadOnly={isReadOnly} setReadOnly={setReadOnly}
        archiveObject={archiveBusinessEntity} artifactType='business_entity' setTags={setTags} getObjectVersion={getBusinessEntityVersion} getObjectVersions={getBusinessEntityVersions} getObject={getBusinessEntity} deleteObject={deleteBusinessEntity}
        restoreObject={restoreBusinessEntity} updateObject={updateBusinessEntity} tabs={[
        {
          key: 'tab-gen',
          title: i18n('Сведения'),
          unscrollable: true,
          content: <div className={styles.tab_2col}>
            <div className={classNames(styles.col, styles.scrollable)}>
              <h2>Общая информация</h2>

              <FieldTextEditor
                  isReadOnly={isReadOnly}
                  label={i18n('Название')}
                  defaultValue={data.entity.name}
                  className=''
                  valueSubmitted={(val) => {
                    updateBEField('name', val);
                  }}
                />

              <FieldAutocompleteEditor
                label={i18n('Домен')}
                defaultValue={data.entity.domain_id}
                valueSubmitted={(i) => updateBEField('domain_id', i)}
                getDisplayValue={getDomainDisplayValue}
                getObjects={getDomainAutocompleteObjects}
                showValidation={showValidation}
                artifactType="domain"
                isReadOnly={isReadOnly}
                allowClear
              />

              <FieldTextareaEditor
                  isReadOnly={isReadOnly}
                  label={i18n('Описание')}
                  defaultValue={data.entity.short_description}
                  valueSubmitted={(val) => {
                    updateBEField('short_description', val ?? '');
                  }}
                />

              <div data-uitest="be_tag" className={styles.tags_block}>
                <div className={styles.label}>{i18n('Теги')}</div>
                <Tags
                  key={'tags-' + businessEntityId + '-' + businessEntityVersionId + '-' + uuid()}
                  isReadOnly={isReadOnly}
                  tags={tags}
                  onTagAdded={(tagName: string) => tagAddedHandler(tagName, businessEntityId, 'business_entity', data.metadata.state ?? '', tags, setLoading, setTags, '/business-entities/edit/', navigate)}
                  onTagDeleted={(tagName: string) => tagDeletedHandler(tagName, businessEntityId, 'business_entity', data.metadata.state ?? '', setLoading, setTags, '/business-entities/edit/', navigate)}
                />
              </div>
            </div>
            <div className={classNames(styles.col, styles.scrollable)}>
              <h2>Дополнительные параметры</h2>

              <FieldTextEditor
                isReadOnly={isReadOnly}
                label={i18n('Техническое название')}
                defaultValue={data.entity.tech_name}
                valueSubmitted={(val) => {
                  updateBEField('tech_name', val);
                }}
              />

              <FieldTextEditor
                isReadOnly={isReadOnly}
                label={i18n('Формула расчета (Бизнес-алгоритм)')}
                defaultValue={data.entity.formula}
                valueSubmitted={(val) => {
                  updateBEField('formula', val);
                }}
              />

              <FieldTextEditor
                isReadOnly={isReadOnly}
                label={i18n('Примеры значений')}
                defaultValue={data.entity.examples}
                valueSubmitted={(val) => {
                  updateBEField('examples', val);
                }}
              />

              <FieldTextEditor
                isReadOnly={isReadOnly}
                label={i18n('Ссылка на справочник')}
                defaultValue={data.entity.link}
                valueSubmitted={(val) => {
                  updateBEField('link', val);
                }}
              />

              <FieldTextEditor
                isReadOnly={isReadOnly}
                label={i18n('Законодательные ограничения')}
                defaultValue={data.entity.limits}
                valueSubmitted={(val) => {
                  updateBEField('limits', val);
                }}
              />

              <FieldTextEditor
                isReadOnly={isReadOnly}
                label={i18n('Ключевые роли процесса')}
                defaultValue={data.entity.roles}
                valueSubmitted={(val) => {
                  updateBEField('roles', val);
                }}
              />

              <FieldAutocompleteEditor
                label={i18n('Родитель')}
                defaultValue={data.entity.parent_id}
                valueSubmitted={(i) => updateBEField('parent_id', i)}
                getDisplayValue={getBusinessEntityDisplayValue}
                getObjects={getParentBEAutocompleteObjects}
                showValidation={showValidation}
                artifactType="business_entity"
                isReadOnly={isReadOnly}
                allowClear
              />

              <FieldAutocompleteEditor
                label={i18n('Тип данных')}
                defaultValue={data.entity.datatype_id}
                valueSubmitted={(i) => updateBEField('datatype_id', i)}
                getDisplayValue={getDataTypeDisplayValue}
                getObjects={getDataTypeAutocompleteObjects}
                showValidation={showValidation}
                artifactType="datatype"
                isReadOnly={isReadOnly}
                allowClear
              />

              <FieldTextareaEditor
                isReadOnly={isReadOnly}
                label={`${i18n('Нормативные документы')}`}
                defaultValue={data.entity.regulation}
                valueSubmitted={(val) => {
                  updateBEField('regulation', val ?? '');
                }}
              />

              <FieldArrayEditor
                isReadOnly={isReadOnly}
                label={`${i18n('Альтернативные наименования')}`}
                defaultValue={data.entity.alt_names}
                valueSubmitted={(val) => {
                  updateBEField('alt_names', val);
                }}
                showValidation={showValidation}
                inputPlaceholder={i18n('Введите наименование')}
                addBtnText={i18n('Добавить наименование')}
                displayValueSeparator='; '
              />

              <div className={classNames(styles.data_row, styles.synonyms_row)}>
                <div className={styles.synonyms_head}>
                  <label>{`${i18n('Синонимы')}:`}</label>
                  {!isReadOnly && (<PlusInCircle onClick={addSynonym} />)}
                </div>
                {data.entity.synonym_ids.map((sId, k) => (
                  <div key={`si${k}-${sId}`} className={styles.synonym_item}>
                    <FieldAutocompleteEditor
                      key={`se${k}`}
                      isReadOnly={isReadOnly}
                      label=""
                      defaultValue={sId}
                      className={styles.synonym_select}
                      valueSubmitted={(identity) => updateBESynonymId(k, identity)}
                      getDisplayValue={getBusinessEntityDisplayValue}
                      getObjects={getBEObjects}
                    />
                    {!isReadOnly && (<Close key={`ds${k}`} onClick={() => delSynonym(k)} />)}
                  </div>
                ))}
              </div>

              <div className={classNames(styles.data_row, styles.synonyms_row)}>
                <div className={styles.synonyms_head}>
                  <label>{`${i18n('Ссылки на другие Термины')}:`}</label>
                  {!isReadOnly && (<PlusInCircle onClick={addBELink} />)}
                </div>
                {data.entity.be_link_ids.map((sId, k) => (
                  <div key={`si22${k}-${sId}`} className={styles.synonym_item}>
                    <FieldAutocompleteEditor
                      key={`se22${k}`}
                      isReadOnly={isReadOnly}
                      label=""
                      defaultValue={sId}
                      className={styles.synonym_select}
                      valueSubmitted={(identity) => updateBELink(k, identity)}
                      getDisplayValue={getBusinessEntityDisplayValue}
                      getObjects={getBELinkObjects}
                    />
                    {!isReadOnly && (<Close key={`ds22${k}`} onClick={() => delBELink(k)} />)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        },
        {
          key: 'tab-related',
          title: i18n('Связи'),
          content: <div className={styles.tab_white}>
            <RelatedObjectsControl artifactId={businessEntityId} artifactType='business_entity'></RelatedObjectsControl>
          </div>
        },
        {
          key: 'tab-desc',
          title: i18n('Расширенное описание'),
          content: <div className={styles.tab_transparent}>

            <FieldVisualEditor
                isReadOnly={isReadOnly}
                defaultValue={data.entity.description}
                className=''
                valueSubmitted={(val) => {
                  updateBEField('description', val);
                }}
              />  
          
          </div>
        }, 
        {
          key: 'tab-def',
          title: i18n('Определение'),
          content: <div className={styles.tab_transparent}>
            <FieldVisualEditor
                  isReadOnly={isReadOnly}
                  defaultValue={data.entity.definition}
                  valueSubmitted={(val) => {
                    updateBEField('definition', val);
                  }}
                />
          </div>
        }
      ]} />
    </>
  );
}
