/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './SettingsSteward.module.scss';
import { getDomainAutocompleteObjects, getUserAutocompleteObjects, getUserDisplayValue, handleHttpError, i18n } from '../../utils';
import { FieldEditor } from '../../components/FieldEditor';
import { createSteward, getSteward, updateSteward } from '../../services/pages/stewards';
import { FieldAutocompleteEditor } from '../../components/FieldAutocompleteEditor';
import { getDomains } from '../../services/pages/domains';
import { getUser, getUsers } from '../../services/pages/users';
import { FieldMultipleCheckboxEditor } from '../../components/FieldMultipleCheckboxEditor';
import useUrlState from '@ahooksjs/use-url-state';
import classNames from 'classnames';
import { Button } from '../../components/Button';
import { ArtifactInfo } from '../../components/ArtifactInfo';
import { StaticNoticesArea } from '../../components/StaticNoticesArea';
import { Tabs } from '../../components/Tabs';
import { FieldTextEditor } from '../../components/FieldTextEditor';

export const SettingsSteward = () => {
  const navigate = useNavigate();

  const [, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  const [state, setState] = useUrlState({ sc: 1 }, { navigateMode: 'replace' });
  const [data, setData] = useState<any>({
    entity: {
      name: null,
      description: null,
      domains: null,
      user_id: null,
    },
  });
  const [showValidation, setShowValidation] = useState(true);
  const [stewardId, setStewardId] = useState<string>('');

  const { id } = useParams();

  useEffect(() => {
    if (!stewardId && id) setStewardId(id);
  }, [id]);

  const loadData = () => {
    if (stewardId) {
      getSteward(stewardId)
        .then((json) => {
          setData(json);
          if (document.getElementById(`crumb_${stewardId}`) !== null) {
            document.getElementById(`crumb_${stewardId}`)!.innerText = json.entity.name;
          }
          setLoading(false);
          setLoaded(true);
        })
        .catch(handleHttpError);
    }
  }

  useEffect(() => {
    loadData();
  }, [stewardId]);

  const updateStewardField = (field: string, value: string | string[] | undefined) => {
    setData((prev: any) => ({ ...prev, entity: { ...prev.entity, [field]: value } }));
  };

  const saveData = () => {
    updateSteward(stewardId, data).then(json => {
      if (json.metadata && json.metadata.id != stewardId)
        navigate(`/settings/stewards/edit/${json.metadata.id}`);
    }).catch(handleHttpError);
  }

  return (
    <div className={classNames(styles.page, styles.transparent, { [styles.loaded]: isLoaded })}>
      <div className={styles.title_row}>
        <h1 className={styles.title}>{data.entity.name}</h1>
        <div className={styles.buttons}>
          <Button background='blue' onClick={() => saveData()}>Сохранить</Button>
          <Button background='outlined-blue' onClick={() => loadData()}>Отменить</Button>
        </div>
      </div>
      <div className={styles.artifact_info_row}>
        <ArtifactInfo artifactType={'steward'} />
      </div>
      <StaticNoticesArea />
      <Tabs onTabChange={(t: number) => { setState(() => ({ sc: t })); }} tabNumber={state.sc} tabs={[
        {
          key: 'tab-gen',
          title: i18n('Сведения'),
          content: <div className={styles.tab_white}>
            <FieldTextEditor
              label={i18n('ФИО')}
              defaultValue={data.entity.name}
              valueSubmitted={(val) => {
                updateStewardField('name', val);
              }}
            />

            <FieldTextEditor
              label={i18n('Описание')}
              defaultValue={data.entity.description}
              valueSubmitted={(val) => {
                updateStewardField('description', val);
              }}
            />

            <FieldAutocompleteEditor
              label={i18n('Пользователь')}
              defaultValue={data.entity.user_id}
              valueSubmitted={(identity) => updateStewardField('user_id', identity)}
              getDisplayValue={getUserDisplayValue}
              getObjects={getUserAutocompleteObjects}
              isRequired
              showValidation={showValidation}
            />

            <FieldMultipleCheckboxEditor
              isScroll
              label={i18n('Домены')}
              defaultValues={data.entity.domains}
              valueSubmitted={(ids) => updateStewardField('domains', ids)}
              dataSet={getDomainAutocompleteObjects}
              isRequired
              showValidation={showValidation}
            />
          </div>
        }
      ]} />
    </div>
  );
};
