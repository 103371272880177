/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import useUrlState from '@ahooksjs/use-url-state';
import styles from './SettingsWorkflow.module.scss';
import { doNavigate, getArtifactActionAutocompleteObjects, getArtifactActionDisplayValue, getArtifactTypeAutocompleteObjects, getArtifactTypeDisplayValue, getPDAutocompleteObjects, getPDDisplayValue, getTablePageSize, handleHttpError, i18n, updateArtifactsCount } from '../../utils';
import { renderDate, Table, TableDataRequest } from '../../components/Table';
import { Loader } from '../../components/Loader';
import { useNavigate } from "react-router-dom";
import { createWorkflowSettings, deleteWorkflowSettings } from '../../services/pages/workflow';
import classNames from 'classnames';
import { Button } from '../../components/Button';
import { DeleteObjectModal } from '../../components/DeleteObjectModal';
import { ModalDlg } from '../../components/ModalDlg';
import { FieldTextEditor } from '../../components/FieldTextEditor';
import { FieldAutocompleteEditor } from '../../components/FieldAutocompleteEditor';

export function SettingsWorkflow() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const [, setLoading] = useState(false);
  const [state, setState] = useUrlState({ p: '1', q: undefined }, { navigateMode: 'replace' });

  const [showDelDlg, setShowDelDlg] = useState(false);
  const [delObjectData, setDelObjectData] = useState<any>({ id: '', username: '' });
  const [showCreateDlg, setShowCreateDlg] = useState(false);
  const [showCreateValidation, setShowCreateValidation] = useState(false);
  const [createData, setCreateData] = useState({ name: '', system_id: '' });

  const columns = [
    { property: 'id', header: 'ID', isHidden: true },
    {
      property: 'num',
      header: i18n('Koд'),
      sortDisabled: true,
      filterDisabled: true,
    },
    {
      property: 'artifact_type_name',
      filter_property: 'at.name',
      sort_property: 'at.name',
      header: i18n('Тип объекта'),
    },
    {
        property: 'artifact_action',
        header: i18n('Событие'),
    },
    { property: 'description', header: i18n('Описание события') },
    {
      property: 'modified',
      header: i18n('Дата создания'),
      render: (row: any) => renderDate(row, 'modified'),
    }
  ];

  const delDlgSubmit = () => {
    setShowDelDlg(false);
    setLoading(true);
    deleteWorkflowSettings(delObjectData.id)
      .then(json => {
        setLoading(false);

        if (json.metadata && json.metadata.id)
          navigate('/settings/workflows/edit/' + encodeURIComponent(json.metadata.id));
      })
      .catch(handleHttpError);
    setDelObjectData({ id: '', name: '' });
  };

  const submitCreate = () => {
    if (createData.name) {
      setShowCreateDlg(false);

      createWorkflowSettings(createData).then(json => {
        if (json && json.metadata.id) {
          navigate(`/settings/workflows/edit/${encodeURIComponent(json.metadata.id)}`);
        }
      }).catch(handleHttpError)
    } else
      setShowCreateValidation(true);
  }

  return (
    <div className={classNames(styles.page, styles.scrollable, { [styles.loaded]: loaded })}>
      {!loaded ? (
        <Loader className="centrify" />
      ) : (
        <>
          <div className={styles.title}>{`${i18n('Процессы')}`}<Button background='blue' onClick={() => { setShowCreateValidation(false); setShowCreateDlg(true); }}>Создать процесс</Button></div>
          <Table
            cookieKey='workflows'
            className={styles.table}
            columns={columns}
            paginate
            columnSearch
            globalSearch
            dataUrl="/v1/workflows/searchSettings"
            initialFetchRequest={{
              sort: 'at.name+',
              global_query: state.q !== undefined ? state.q : '',
              limit: getTablePageSize(),
              offset: (state.p - 1) * getTablePageSize(),
              filters: [],
              filters_preset: [],
              filters_for_join: [],
            }}
            onRowClick={(row: any) => {
              navigate(`/settings/workflows/edit/${encodeURIComponent(row.id)}`);
            }}
            renderActionsPopup={(row: any) => (
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate('/settings/workflows/edit/');
                    return false;
                  }}
                  className={styles.btn_create}
                />
                <a
                  href={`/settings/workflows/edit/${encodeURIComponent(row.id)}`}
                  className={styles.btn_edit}
                  onClick={(e) => { e.preventDefault(); navigate(`/settings/workflows/edit/${encodeURIComponent(row.id)}`); }}
                />
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setDelObjectData({ id: row.id });
                    setShowDelDlg(true);
                    return false;
                  }}
                  className={styles.btn_del}
                />
              </div>
            )}
            onPageChange={(page: number) => (
              setState(() => ({ p: page }))
            )}
            onQueryChange={(query: string) => (
              setState(() => ({ p: undefined, q: query }))
            )}
          />
          
          <DeleteObjectModal show={showDelDlg} objectTitle={delObjectData.username} onClose={() => { setShowDelDlg(false); return false; }} onSubmit={delDlgSubmit} />
          <ModalDlg show={showCreateDlg} title={i18n('Создать стюарда')} cancelBtnText={i18n('Отменить')} submitBtnText={i18n('Создать')} onClose={() => setShowCreateDlg(false)} dialogClassName={styles.dlg_create} onSubmit={submitCreate}>
            <div className={styles.fields}>
              <FieldAutocompleteEditor
                label={i18n('Тип объекта')}
                defaultValue={''}
                valueSubmitted={(v) => setCreateData((prev:any) => ({...prev, artifact_type: v}))}
                getDisplayValue={getArtifactTypeDisplayValue}
                getObjects={getArtifactTypeAutocompleteObjects}
                isRequired
                showValidation={showCreateValidation}
              />
              <FieldAutocompleteEditor
                  label={i18n('Событие')}
                  defaultValue={''}
                  valueSubmitted={(v) => setCreateData((prev:any) => ({...prev, artifact_action: v}))}
                  getDisplayValue={getArtifactActionDisplayValue}
                  getObjects={getArtifactActionAutocompleteObjects}
                  isRequired
                  showValidation={showCreateValidation}
                />
              <FieldAutocompleteEditor
                  label={i18n('Process Definition')}
                  defaultValue={''}
                  valueSubmitted={(v) => setCreateData((prev:any) => ({...prev, process_definition_key: v}))}
                  getDisplayValue={getPDDisplayValue}
                  getObjects={getPDAutocompleteObjects}
                  showValidation={showCreateValidation}
                />
                
                <FieldTextEditor
                    label={i18n('Описание события')}
                    defaultValue={''}
                    valueSubmitted={(v) => setCreateData((prev:any) => ({...prev, description: v}))}
                    isRequired
                />
            </div>
          </ModalDlg>
          
        </>
      )}
    </div>
  );
}
