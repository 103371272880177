/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react';
import styles from './SettingsConnections.module.scss';
import { getSystemAutocompleteObjects, getSystemDisplayValue, getTablePageSize, handleHttpError, i18n } from '../../utils';
import { Table } from '../../components/Table';
import { Loader } from '../../components/Loader';
import { createSystemConnection, deleteSystemConnection } from '../../services/pages/systemConnections';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';
import { DeleteObjectModal } from '../../components/DeleteObjectModal';
import { ModalDlg } from '../../components/ModalDlg';
import { FieldTextEditor } from '../../components/FieldTextEditor';
import { Button } from '../../components/Button';
import { FieldAutocompleteEditor } from '../../components/FieldAutocompleteEditor';
import { TaskParamsControl } from '../../components/TaskParamsControl/TaskParamsControl';

export function SettingsConnections() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  
  const [showDelDlg, setShowDelDlg] = useState(false);
  const [delObjectData, setDelObjectData] = useState<any>({ id: '', username: '' });
  const [showCreateDlg, setShowCreateDlg] = useState(false);
  const [showCreateValidation, setShowCreateValidation] = useState(false);
  const [createData, setCreateData] = useState({ name: '', system_id: '' });
  const [newConnectionData, setNewConnectionData] = useState({});

  const delDlgSubmit = () => {
    setShowDelDlg(false);
    deleteSystemConnection(delObjectData.id)
      .then(() => {
      })
      .catch(handleHttpError);
    setDelObjectData({ id: '', name: '' });
  };

  const columns = [
    { property: 'id', header: 'ID', isHidden: true },
    {
      property: 'name',
      header: i18n('Название'),
    },
    {
      property: 'connector_name',
      header: i18n('Тип подключения'),
    },
    {
      property: 'system_name',
      header: i18n('Система')
    },
  ];

  const submitCreate = () => {
    if (createData.name && createData.system_id) {
      setShowCreateDlg(false);

      createSystemConnection({...createData, ...newConnectionData, enabled: true }).then(json => {
        if (json && json.metadata.id) {
          navigate(`/settings/connections/edit/${encodeURIComponent(json.metadata.id)}`);
        }
      }).catch(handleHttpError)
    } else
      setShowCreateValidation(true);
  }

  return (
    <div className={classNames(styles.page, styles.scrollable, { [styles.loaded]: loaded })}>
      {!loaded ? (
        <Loader className="centrify" />
      ) : (
        <>
          <div className={styles.title}>{`${i18n('Подключения')}`}<Button background='blue' onClick={() => { setShowCreateValidation(false); setShowCreateDlg(true); }}>Создать подключение</Button></div>
          <Table
            cookieKey='connections'
            className={styles.table}
            columns={columns}
            paginate
            columnSearch
            globalSearch
            dataUrl="/v1/system_connections/search"
            initialFetchRequest={{
              sort: 'name+',
              global_query: '',
              limit: getTablePageSize(),
              offset: 0,
              filters: [],
              filters_preset: [],
              filters_for_join: [],
            }}
            onRowClick={(row: any) => {
              navigate(`/settings/connections/edit/${encodeURIComponent(row.id)}`);
            }}
            renderActionsPopup={(row: any) => (
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate('/settings/connections/edit/');
                    return false;
                  }}
                  className={styles.btn_create}
                />
                <a
                  href={`/settings/connections/edit/${encodeURIComponent(row.id)}`}
                  className={styles.btn_edit}
                  onClick={(e) => { e.preventDefault(); navigate(`/settings/connections/edit/${encodeURIComponent(row.id)}`); }}
                />
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setDelObjectData({ id: row.id, name: row.name });
                    setShowDelDlg(true);
                    return false;
                  }}
                  className={styles.btn_del}
                />
              </div>
            )}
          />
          <DeleteObjectModal show={showDelDlg} objectTitle={delObjectData.username} onClose={() => { setShowDelDlg(false); return false; }} onSubmit={delDlgSubmit} />
          <ModalDlg show={showCreateDlg} title={i18n('Создать подключение')} cancelBtnText={i18n('Отменить')} submitBtnText={i18n('Создать')} onClose={() => setShowCreateDlg(false)} dialogClassName={styles.dlg_create} onSubmit={submitCreate}>
            <div className={styles.fields}>
                <FieldTextEditor label={i18n('Название')} isRequired showValidation={showCreateValidation} defaultValue='' valueSubmitted={(v) => setCreateData((prev) => ({...prev, name: v ?? ''}))} />

                <FieldAutocompleteEditor
                  label={i18n('Система')}
                  defaultValue={''}
                  valueSubmitted={(v) => setCreateData((prev) => ({...prev, system_id: v ?? ''}))}
                  getDisplayValue={getSystemDisplayValue}
                  getObjects={getSystemAutocompleteObjects}
                  isRequired
                  showValidation={showCreateValidation}
                />

                <TaskParamsControl
                  onChangedConnection={(connData) => {
                    setNewConnectionData(connData);
                  }}
                  defaultConnectionData={createData}
                  useScheduler={false}
                />

            </div>
          </ModalDlg>
        </>
      )}
    </div>
  );
}
