/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import useUrlState from '@ahooksjs/use-url-state';
import styles from './Account.module.scss';
import { getCookie, handleHttpError, i18n, updateArtifactsCount } from '../../utils';
import { renderDate, Table, TableDataRequest } from '../../components/Table';
import { Loader } from '../../components/Loader';
import { deleteDomain } from '../../services/pages/domains';
import { useNavigate } from "react-router-dom";
import { getUserByLogin, updateUserPassword } from '../../services/pages/users';
import { Prev } from 'react-bootstrap/esm/PageItem';
import { Button } from '../../components/Button';
import classNames from 'classnames';
import { FieldTextEditor } from '../../components/FieldTextEditor';

export function Account() {
  const navigate = useNavigate();
  const [state, setState] = useUrlState({ p: '1', q: undefined }, { navigateMode: 'replace' });
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({ login: '', email: '' });
  const [newPasswordData, setNewPasswordData] = useState({ old_password: '', new_password: '', confirm_password: '' });
  
  useEffect(() => {
    getUserByLogin(getCookie('login') || '').then(json => {
        setData(json);
    });
  }, []);

  const updatePassword = () => {
    updateUserPassword(newPasswordData).then(json => {
    });
  };

  return (
    <div className={classNames(styles.page, styles.loaded)}>
      {loading ? (
        <Loader className="centrify" />
      ) : (
        <>
          <div className={styles.title}>{`${i18n('Профиль')}`}</div>
          
          <div className={styles.fields}>
            <FieldTextEditor label={i18n('Логин')} defaultValue={getCookie('login')} isReadOnly valueSubmitted={()=>{}} />
            <FieldTextEditor label={i18n('E-mail')} defaultValue={data.email} isReadOnly valueSubmitted={()=>{}} />
            
            <div className={styles.title2}>{`${i18n('Смена пароля')}`}</div>
            
            <FieldTextEditor isPassword id='inp-old-psw' label={i18n('Старый пароль')} defaultValue={newPasswordData.old_password} valueSubmitted={(v) => { setNewPasswordData(prev => ({...prev, old_password: v ?? '' })) }} />
            <FieldTextEditor isPassword id='inp-new-psw' label={i18n('Новый пароль')} defaultValue={newPasswordData.new_password} valueSubmitted={(v) => { setNewPasswordData(prev => ({...prev, new_password: v ?? '' })) }} />
            <FieldTextEditor isPassword id='inp-conf-psw' label={i18n('Повторите пароль')} defaultValue={newPasswordData.confirm_password} valueSubmitted={(v) => { setNewPasswordData(prev => ({...prev, confirm_password: v ?? '' })) }} />
          </div>
          <div className={styles.buttons}>
            <Button background="outlined-blue" onClick={updatePassword}>Сохранить</Button>
          </div>
        </>
      )}
    </div>
  );
}
