/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './SettingsRoles.module.scss';
import { handleHttpError, i18n } from '../../utils';
import { FieldEditor } from '../../components/FieldEditor';
import { createRole, getPermissions, getRole, updateRole } from '../../services/pages/roles';
import { Checkbox } from '../../components/Checkbox';
import useUrlState from '@ahooksjs/use-url-state';
import classNames from 'classnames';
import { Button } from '../../components/Button';
import { ArtifactInfo } from '../../components/ArtifactInfo';
import { StaticNoticesArea } from '../../components/StaticNoticesArea';
import { Tabs } from '../../components/Tabs';
import { FieldTextEditor } from '../../components/FieldTextEditor';

export const SettingsRole = () => {
  const navigate = useNavigate();

  const [, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  const [state, setState] = useUrlState({ sc: 1 }, { navigateMode: 'replace' });
  const [data, setData] = useState({ name: '', description: '', permissions: [] as string[] });
  const [perms, setPerms] = useState<PermissionData[]>([]);
  const [showValidation, setShowValidation] = useState(true);
  const [roleId, setRoleId] = useState<string>('');

  const { id } = useParams();

  type PermissionData = {
    name: string;
    description: string;
    id: string;
  };

  const permissionsContain = (s: string) => data.permissions.some((value) => value === s);

  useEffect(() => {
    if (!roleId && id) setRoleId(id);
  }, [id]);

  useEffect(() => {
    getPermissions().then((json) => {
      setPerms(
        json.map((x: any) => ({
          name: x.name,
          description: x.description,
          id: x.id,
        })),
      );
    });
  }, []);

  const loadData = () => {
    if (roleId) {
      getRole(roleId)
        .then((json: any) => {
          setData(json);
          if (document.getElementById(`crumb_${roleId}`) !== null) {
            document.getElementById(`crumb_${roleId}`)!.innerText = json.name;
          }
          setLoading(false);
          setLoaded(true);
        })
        .catch(handleHttpError);
    }
  }

  useEffect(() => {
    loadData();
  }, [roleId]);

  const updateRoleField = (field: string, value: string | undefined) => {
    setData((prev: any) => ({ ...prev, entity: { ...prev.entity, [field]: value } }));
  };

  const roleChanged = (v: string, checked: boolean) => {
    const p = data.permissions;
    if (checked) {
      p.push(v);
    } else {
      const index = p.indexOf(v);
      if (index !== -1) {
        p.splice(index, 1);
      }
    }
    setData((prev: any) => ({ ...prev, permissions: p }));
    /*const dataPerm: any = {};
    dataPerm.permissions = p;
    updateRole(roleId, dataPerm)
      .then(() => { })
      .catch(handleHttpError);*/
  };

  const saveData = () => {
    updateRole(roleId, data).then(json => {
      if (json.metadata && json.metadata.id != roleId)
        navigate(`/settings/roles/edit/${json.metadata.id}`);
    }).catch(handleHttpError);
  }

  return (
    <div className={classNames(styles.page, styles.transparent, { [styles.loaded]: isLoaded })}>
      <div className={styles.title_row}>
        <h1 className={styles.title}>{data.name}</h1>
        <div className={styles.buttons}>
          <Button background='blue' onClick={() => saveData()}>Сохранить</Button>
          <Button background='outlined-blue' onClick={() => loadData()}>Отменить</Button>
        </div>
      </div>
      <div className={styles.artifact_info_row}>
        <ArtifactInfo artifactType={'role'} />
      </div>
      <StaticNoticesArea />
      <Tabs onTabChange={(t: number) => { setState(() => ({ sc: t })); }} tabNumber={state.sc} tabs={[
        {
          key: 'tab-gen',
          title: i18n('Сведения'),
          content: <div className={styles.tab_white}>
            <FieldTextEditor
              isReadOnly={false}
              label={i18n('Роль')}
              defaultValue={data.name}
              valueSubmitted={(val) => {
                updateRoleField('name', val);
              }}
              isRequired
              showValidation={showValidation}
            />

            <FieldTextEditor
              isReadOnly={false}
              label={i18n('Описание')}
              defaultValue={data.description}
              valueSubmitted={(val) => {
                updateRoleField('description', val);
              }}
              isRequired
            />

            {(perms) && (
              <div className={styles.checkboxes}>
                {perms.map((permission: PermissionData) => (
                  <div className={styles.row}>
                    <Checkbox
                      key={permission.name}
                      id={permission.name}
                      name={permission.name}
                      label={permission.name}
                      className={styles.checkbox}
                      checked={permissionsContain(permission.name)}
                      value={permission.name}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        roleChanged(e.target.value, e.target.checked);
                      }}
                    />
                    <div className={styles.description}>{permission.description}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        }
      ]} />
    </div>
  );
};
